
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import { AxiosError } from "axios";
    import RoutesEnum from "@/router/routesEnum";
    import FormInscription from "@/app/authentication/views/FormInscription.vue";
    import InscriptionSucess from "@/app/authentication/views/InscriptionSucess.vue";

    @Component
    export default class Interface extends Vue
    {
         private errortest: boolean = false;
    }
